<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--13">
      <system-nav></system-nav>
      <section class="main-content">
        <section class="section">
          <div class="section__titles">
            <h4 class="section__title">{{ group.name }}</h4>
            <button class="btn btn--primary btn--outline btn--sm" @click.prevent="showNewInviteDialog()">Agregar administrador</button>
          </div>
          <div class="list user-list">
            <div class="list__item user" v-for="person in persons.filter(n => !n.primerLogin)" :key="person.idUsu">
              <div class="user__info">
                <div v-if="person.imgUsu" class="user__image">
                  <img referrerpolicy="no-referrer" :src="person.imgUsu">
                </div>
                <div v-else class="user__initials">
                  {{ person.corrUsu?.slice(0,2).toUpperCase() }}
                </div>
                <div class="user__data">
                  <h5>{{ person.nomUsu }} {{ person.apePatUsu }} {{ person.apeMatUsu }}</h5>
                  <p class="caption">{{ person.corrUsu }}</p>
                </div>
              </div>
              <div class="user-actions">
                <DropdownMenu>
                  <template #button>
                    <button class="btn btn--icon btn--circle">
                      <img class="icon" src="@/assets/images/icons/ico_dots--bl.svg">
                    </button>
                  </template>
                  <template #options>
                    <!--<div class="dropdown__option" @click.prevent="this.$router.push({name: 'EditProfile', params: {id: person.idUsu}})">
                      Editar
                    </div>-->
                    <div class="dropdown__option" @click.prevent="removeAdmin(person.corrUsu)">
                      Remover permisos
                    </div>
                  </template>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </section>

        <section class="section" v-if="persons.filter(n => n.primerLogin).length > 0">
          <div class="section__titles">
            <h4 class="section__title">Administradores pendientes de acceso</h4>
          </div>
          <div class="list user-list">
            <div class="list__item user" v-for="person in persons.filter(n => n.primerLogin)" :key="person.idUsu">
              <div class="user__info">
                <div class="user__initials">
                  {{ person.corrUsu?.slice(0,2).toUpperCase() }}
                </div>
                <div class="user__data">
                  <h5>{{ person.corrUsu }}</h5>
                </div>
              </div>
              <div class="user-actions">
                <DropdownMenu>
                  <template #button>
                    <button class="btn btn--icon btn--circle">
                      <img class="icon" src="@/assets/images/icons/ico_dots--bl.svg">
                    </button>
                  </template>
                  <template #options>
                    <div class="dropdown__option" @click.prevent="removeAdmin(person.corrUsu)">
                      Remover permisos
                    </div>
                  </template>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>

  <!-- Nueva invitación -->
  <modal-dialog v-if="newInviteDialog">
    <template #title>Agregar administrador</template>
    <template #description>Agregar el correo electrónico del administrador que quieres agregar</template>
    <template #content>
      <form class="form" @submit.prevent="inviteAdmin()">
        <div class="form__group">
          <label class="form__label" for="email">Correo electrónico</label>
          <input class="form__input" id="email" type="email" v-model.trim="newInvite.corrUsu" :disabled="submitting">
        </div>
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="newInviteDialog = false" :disabled="submitting">Cancelar</button>
          <button class="btn btn--primary" type="submit" :disabled="submitting">Agregar</button>
        </div>
      </form>
    </template>
  </modal-dialog>

  <!-- Cancelar permisos -->
  <modal-dialog v-if="removeAdminPrivilegesDialog">
    <template #title>Quitar permisos</template>
    <template #description>¿Desea eliminar los permisos de administrador para este usuario?</template>
    <template #content>
      <form class="form" @submit.prevent="removeAdminConfirm()">
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="removeAdminPrivilegesDialog = false" :disabled="submitting">Cancelar</button>
          <button class="btn btn--primary" type="submit" :disabled="submitting">Confirmar</button>
        </div>
      </form>
    </template>
  </modal-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import SystemNav from '@/components/SystemNav.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'

export default {
  components: {
    SideNav,
    SystemNav,
    ModalDialog,
    DropdownMenu
  },
  name: 'Group',
  data() {
    return {
      group: {
        id: 1,
        name: 'Administradores',
        description: 'Los administradores pueden administrar todo el sistema'
      },
      persons: [],
      roles: [
        {id: 1, route: 'admins'},
        {id: 2, route: 'users'}
      ],
      newInviteDialog: false,
      removeAdminPrivilegesDialog: false,
      newInvite: {},
      submitting: false,
      route: '',
      adminToRemove: null
    }
  },
  async created() {
    this.route = this.roles.find(n => n.id == this.$route.params.groupId).route
    await this.getUsers()
  },
  methods: {
    showNewInviteDialog() {
      this.newInviteDialog = true
    },
    async getUsers() {
      this.persons = (await this.$axios.post(`Users/${this.route}`)).data;
    },
    isValidEmail(email){
      var validator = require("email-validator");
      return validator.validate(email);
    },
    async inviteAdmin(){
      let res = (await this.$axios.post(`Users/checkEmail`,{
        email: this.newInvite.corrUsu
      })).data
      if(res){
        this.$toast.error('El correo utilizado ya está en uso')
      }
      else{
        this.submitting = true
        if(this.isValidEmail(this.newInvite.corrUsu)){
          res = (await this.$axios.post('Users/admin/invite', {
            toMail: this.newInvite.corrUsu,
            jwt: this.$cookies.get('jwtA')
          })).data
          if(res){
            this.$toast.success('La invitación ha sido enviada con éxito')
            this.newInviteDialog = false
            this.newInvite.corrUsu = ''
            this.getUsers()
          }
          else
            this.$toast.error('Ocurrió un error al invitar al usuario')
        }
        else{
          this.$toast.error('El correo utilizado no es válido')
        }
        this.submitting = false
      }
    },
    removeAdmin(email){
      this.removeAdminPrivilegesDialog = true
      this.adminToRemove = email
    },
    async removeAdminConfirm(){
      const res = await this.$axios.post('Users/admin/remove', {
        email: this.adminToRemove
      })
      if(res.status == 200){
        this.$toast.success('Permisos de administrador removidos con éxito')
        this.removeAdminPrivilegesDialog = false
        this.persons = this.persons.filter( obj => obj.corrUsu !== this.adminToRemove)
        this.adminToRemove = null
      }
      else
        this.$toast.error('Ocurrió un error al eliminar los permisos')
    }
  }
}
</script>